body::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

* {
	transition: all 0.2s ease;
}
.container {
	margin: 0 auto;
	padding-left: 10px;
	padding-right: 10px;
}

.slider-content {
	padding-left: 300px;

	transform: translateX(0);
	z-index: 1;
	transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
	transition-timing-function: ease, ease, ease;
	transition-delay: 0s, 0s, 0s;
	-webkit-transition-delay: 0.3s !important;
	transition-delay: 0.3s !important;
	transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.slider-content-small {
	margin-top: 10px;
	padding-left: 300px;
	min-width: 500px;
	transform: translateX(0);
	z-index: 1;

	transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
	transition-timing-function: ease, ease, ease;
	transition-delay: 0s, 0s, 0s;
	-webkit-transition-delay: 0.3s !important;
	transition-delay: 0.3s !important;
	transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.slider-content::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: #000;
	background: rgba(0, 0, 0, 0.9);
	right: 0;
	top: 0;
	transform: skew(-20deg);
	z-index: -1;
}

.slider-content-small::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.534);
	right: 0;
	top: 0;
	transform: skew(-20deg);
	z-index: -1;
}

.react-tabs {
	display: flex;
	flex-direction: column;
	/* margin-left: 36px; */
	width: 100%;
	/* height: 400px; */
	/* border: 1px solid #3c3e43; */
	color: #333;
	background: #fff;
}

.react-tabs__tab-list {
	display: flex;
	/* flex-direction: row; */
	width: 100%;
	margin: 0;
	padding: 0;
	color: black;
	border-bottom: 1px solid #ddd;
}

.react-tabs__tab {
	list-style: none;
	padding: 16px 6px;
	cursor: pointer;
	color: #bbb;
	border-right: 1px solid #ccc;
	/* border-top: 1px solid transparent; */
	border-collapse: collapse;
	background-color: #fff;
}

.react-tabs__tab:focus {
	outline: none;
}

.react-tabs__tab--selected {
	background: linear-gradient(to bottom, #1d528b, #118ab7);
	color: #fff;
}
.react-tabs__tab:hover {
	background: linear-gradient(to bottom, #1d528b, #118ab7);
	color: #fff;
}

.react-tabs__tab-panel {
	display: none;
	background: #fff;
	padding: 10px;
	width: 100%;
}

.react-tabs__tab-panel--selected {
	display: block;
}

.react-tabs__tab {
	padding-left: 16px;
}

.react-tabs__tab--selected {
	padding-left: 16px;
}

.panel-content {
	padding: 10px;
	background-color: #fff;
	/* text-align: center; */
}

.table-responsive {
	overflow-x: auto;
	min-height: 0.01%;
}
.table-bordered {
	border: 1px solid #ddd;
}
.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
}
table {
	background-color: transparent;
	border-collapse: collapse;
	border-spacing: 0;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
	border: 1px solid #ddd;
}

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
	padding: 5px;
}

.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	padding: 8px;
	line-height: 1.42857143;
	vertical-align: top;
	border-top: 1px solid #ddd;
}

/* @media screen and (max-width: 480px) {
	.react-tabs {
		margin: 0;
		flex-direction: column;
	}
	.react-tabs__tab-list {
		width: 100%;
		flex-direction: row;
	}
	.react-tabs__tab {
		padding: 8px 10px;
	}
	.react-tabs__tab-panel {
		width: 100%;
	}
} */

/* since nested groupes are not supported we have to use 
     regular css for the nested dropdowns 
  */
#dropdown * {
	text-transform: uppercase;
}
#dropdown li > ul {
	transform: translatex(100%) scale(0);
}
#dropdown li:hover > ul {
	transform: translatex(101%) scale(1);
}
#dropdown li > button svg {
	transform: rotate(-90deg);
}
#dropdown li:hover > button svg {
	transform: rotate(-270deg);
}

/* Below styles fake what can be achieved with the tailwind config
     you need to add the group-hover variant to scale and define your custom
     min width style.
  	 See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
  	 for implementation with config file
  */
#dropdown .group:hover .group-hover\:scale-100 {
	transform: scale(1);
}
#dropdown .group:hover .group-hover\:-rotate-180 {
	transform: rotate(180deg);
}
#dropdown .scale-0 {
	transform: scale(0);
}
#dropdown .min-w-32 {
	min-width: 8rem;
}



#htmlDetails img{
	margin: auto;
}

#htmlDetails h2 {
	font-size: 2rem;
}